import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import AuthBanner from '../../components/authentication/AuthBanner';
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
  LoginJWT,
} from '../../components/authentication/login';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import { UserAPI } from '../../api/UserAPI';
import { OauthSender } from 'react-oauth-flow';

const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  Firebase: '/static/icons/firebase.svg',
  JWT: '/static/icons/jwt.svg',
};

const Login: FC = () => {
  const { platform } = useAuth() as any;

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>Login | Value Connect</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <AuthBanner />
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Log in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    자동으로 회원가입이 진행됩니다.
                  </Typography>
                </div>
                <OauthSender
                  authorizeUrl="https://accounts.google.com/o/oauth2/v2/auth"
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  redirectUri={UserAPI.getAuthGoogleCallback()}
                  // asPath 뭘로 넣어ㅑ 하는가
                  state={{
                    asPath: location.pathname,
                    pathname: location.pathname,
                  }}
                  render={({ url }: any) => (
                    <Button
                      href={url}
                      color="primary"
                      fullWidth
                      startIcon={
                        <Box
                          alt="Google"
                          component="img"
                          src="/static/icons/google.svg"
                          sx={{ mr: 1 }}
                        />
                      }
                      size="large"
                      variant="contained"
                    >
                      Login with Google
                    </Button>
                  )}
                  args={{
                    scope: 'profile https://mail.google.com',
                    // for refresh token
                    prompt: 'consent',
                    access_type: 'offline',
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
