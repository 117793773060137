export interface Token {
  access_token: string;
  refresh_token: string;
  token_type: string;
  scope: string;
  expires_in: number;
}

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  GUEST = 'GUEST',
}

export interface User {
  id: number;
  email: string;
  name: string;
  picture: string;
  role: UserRole;
}

export interface UserProfile {
  id: string;
  email: string;
  picture: string;
  name: string;
}
