import { requestApi, requestApiWithAuthentication } from './request';
import {
  ListDataParams,
  ListResponseResult,
  SingleResponseResult,
} from '../models/api/ResponseData';
import { Memo } from '../models/Common';
import sanitize from '../utils/sanitize';

export enum RESOURCE {
  COMPANY = 'companies',
  CANDIDATE = 'candidates',
  POSITION = 'positions',
  MAIL = 'mails',
  MAIL_TEMPLATE = 'mail-templates',
  COMMUTE = 'commutes',
  CRAWLING = 'crawling',
  USER = 'users',
  POSITION_CANDIDATES = 'position-candidates',
}

export interface CreateMemoParams {
  resourceId: number;
  content: string;
}

export interface UpdateMemoParams {
  memoId: number;
  content: string;
}

export const RESOURCE_SINGULAR = {
  [RESOURCE.USER]: 'user',
};

export abstract class CommonAPI<T> {
  resource = null;

  public loadListData = async ({
    size,
    page,
    sort,
    ...params
  }: ListDataParams): Promise<ListResponseResult<T>> => {
    const axiosResponse = await requestApi(this.resource, {
      method: 'get',
      params: {
        size,
        page,
        sort,
        ...params,
      },
    });

    return sanitize(axiosResponse.data);
  };

  public loadData = async (id: number): Promise<SingleResponseResult<T>> => {
    const axiosResponse = await requestApi(`${this.resource}/${id}`, {
      method: 'get',
    });

    return sanitize(axiosResponse.data);
  };

  public createData = async (params: any): Promise<SingleResponseResult<T>> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}`,
      {
        method: 'POST',
        data: params,
      },
    );

    return axiosResponse.data;
  };

  public updateData = async (params: any): Promise<SingleResponseResult<T>> => {
    const axiosResponse = await requestApi(`${this.resource}`, {
      method: 'PUT',
      data: params,
    });

    return axiosResponse.data;
  };

  public deleteData = async (
    id: number,
  ): Promise<SingleResponseResult<boolean>> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}/${id}`,
      {
        method: 'DELETE',
      },
    );

    return axiosResponse.data;
  };

  public loadMemoList = async (
    resourceId: number,
  ): Promise<ListResponseResult<Memo>> => {
    const axiosResponse = await requestApi(
      `${this.resource}/${resourceId}/memos`,
      {
        method: 'get',
      },
    );

    return axiosResponse.data;
  };

  public createMemo = async ({
    resourceId,
    content,
  }: CreateMemoParams): Promise<SingleResponseResult<Memo>> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}/${resourceId}/memos`,
      {
        method: 'POST',
        data: {
          content,
        },
      },
    );

    return axiosResponse.data;
  };

  public updateMemo = async ({
    memoId,
    content,
  }: UpdateMemoParams): Promise<SingleResponseResult<Memo>> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}/memos/${memoId}`,
      {
        method: 'PUT',
        data: {
          content,
        },
      },
    );

    return axiosResponse.data;
  };

  public deleteMemo = async (
    memoId: number,
  ): Promise<SingleResponseResult<boolean>> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}/memos/${memoId}`,
      {
        method: 'DELETE',
      },
    );

    return axiosResponse.data;
  };
}
