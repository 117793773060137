import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import './i18n';
import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import ReactGA from 'react-ga';
import { Worker } from '@react-pdf-viewer/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { EnvChecker } from './utils/EnvChecker';

const queryClient = new QueryClient();

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();

  useEffect(() => {
    if (EnvChecker.isProduction()) {
      Sentry.init({
        dsn: 'https://35646cc5a0bf727f5dd3fa284dc440b6@o937676.ingest.us.sentry.io/4507424654557184',
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/api\.valueconnect\.kr\/api/,
          /^https:\/\/hj-test-api\.fly\.dev\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }, []);

  useEffect(() => {
    ReactGA.initialize('G-Y3NPLKK640');
    ReactGA.pageview(window.location.pathname + window.location.search);

    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    //responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <StyledEngineProvider injectFirst>
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <RTL direction={settings.direction}>
                <CssBaseline />
                <Toaster position="top-center" />
                {/*<SettingsDrawer />*/}
                {auth.isInitialized ? content : <SplashScreen />}
                <ReactQueryDevtools initialIsOpen={false} />
              </RTL>
            </Worker>
          </QueryClientProvider>
        </MuiThemeProvider>
      </StyledThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
