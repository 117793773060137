import { CommonAPI, RESOURCE } from './CommonAPI';
import {
  SingleResponseResult,
  ListResponseResult,
  ListDataParams,
} from '../models/api/ResponseData';
import {
  paramsToFormData,
  postRequestWithFormData,
  putRequestWithFormData,
  requestApi,
  requestApiWithAuthentication,
} from './request';
import { User } from '../models/User';
import { MyTodayCommute } from '../models/Commute';
import { MailTemplate } from '../models/MailTemplate';

export interface LoadMailTemplateParams {
  query: string;
}

export type CreateMailTemplateParams = {
  title: string;
  content: string;
  isPrivate: boolean;
};

export type UpdateMailTemplateParams = Partial<CreateMailTemplateParams>;

export type ListMailTemplateResponse = ListResponseResult<MailTemplate>;
export type SingleMailTemplateResponse = SingleResponseResult<MailTemplate>;

export class MailTemplateAPIClass {
  resource = RESOURCE.MAIL_TEMPLATE;

  public loadMailTemplates = async ({
    query,
  }: LoadMailTemplateParams): Promise<ListMailTemplateResponse> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}`,
      {
        method: 'get',
        params: {
          query,
        },
      },
    );

    return axiosResponse.data;
  };

  public loadMailTemplate = async (
    id: number,
  ): Promise<SingleMailTemplateResponse> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}/${id}`,
      {
        method: 'get',
      },
    );

    return axiosResponse.data;
  };

  public createMailTemplate = async (
    params: CreateMailTemplateParams,
  ): Promise<SingleMailTemplateResponse> => {
    const axiosResponse = await postRequestWithFormData(
      this.resource,
      paramsToFormData(params),
    );

    return axiosResponse.data;
  };

  public updateMailTemplate = async (
    id: number,
    params: UpdateMailTemplateParams,
  ): Promise<SingleMailTemplateResponse> => {
    const axiosResponse = await putRequestWithFormData(
      `${this.resource}/${id}`,
      paramsToFormData(params),
    );

    return axiosResponse.data;
  };

  public deleteMailTemplate = async (id: number): Promise<boolean> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}/${id}`,
      {
        method: 'delete',
      },
    );

    return axiosResponse.data;
  };
}

export const MailTemplateAPI = new MailTemplateAPIClass();
