import { Box, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { FC, useEffect, useState } from 'react';
import { MainItem } from '../../../gnb';
import DashboardNavbarLeftPopup from './DashboardNavbarLeftPopup';
import { matchPath, useLocation } from 'react-router-dom';

interface DashboardNavbarLeftItemProps {
  mainItem: MainItem;
}

const DashboardNavbarLeftItem: FC<DashboardNavbarLeftItemProps> = props => {
  const { mainItem } = props;

  const location = useLocation();

  const [show, setShow] = useState(false);

  const handleNavItemMouseEnter = () => {
    setShow(true);
  };

  const handleNavItemMouseLeave = () => {
    setShow(false);
  };

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={{
        boxSizing: 'border-box',
        position: 'relative',
        ml: 1,
        px: 2,
        height: '64px',
        cursor: 'pointer',
        ...(mainItem.items.filter(
          item => !!matchPath(item.path, location.pathname),
        ).length !== 0 && {
          borderBottom: '4px solid #938FEA',
          paddingTop: '4px',
        }),
      }}
      onMouseEnter={handleNavItemMouseEnter}
      onMouseLeave={handleNavItemMouseLeave}
    >
      <Stack
        flexDirection="row"
        display="inline-flex"
        alignItems="center"
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            '& > svg': {
              fontSize: '18px',
            },
          }}
        >
          {mainItem.icon}
        </Stack>
        <Typography sx={{ ml: 1, fontWeight: 500, fontSize: '15px', mr: 0.5 }}>
          {mainItem.title}
        </Typography>
        <KeyboardArrowDownIcon fontSize="small" />
      </Stack>
      {show && (
        <Box
          sx={{
            width: '180px',
            position: 'absolute',
            zIndex: 100,
            left: '-6px',
            top: '54px',
            backgroundColor: 'white',
            borderRadius: 1,
            boxShadow: '0 5px 6px rgb(52 58 64 / 10%)',
          }}
        >
          <DashboardNavbarLeftPopup subItems={mainItem.items} />
        </Box>
      )}
    </Stack>
  );
};

export default DashboardNavbarLeftItem;
