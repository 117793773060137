import { CommonAPI, RESOURCE } from './CommonAPI';
import {
  SingleResponseResult,
  ListResponseResult,
  ListDataParams,
} from '../models/api/ResponseData';
import {
  paramsToFormData,
  postRequestWithFormData,
  requestApi,
  requestApiWithAuthentication,
} from './request';
import { User } from '../models/User';
import { MyTodayCommute } from '../models/Commute';
import {
  AllMailStatisticsRes,
  Mail,
  MailPreview,
  MailStatisticsRes,
  MyMailStatistics,
} from '../models/Mail';

export type SingleMailStatisticsResponse =
  SingleResponseResult<AllMailStatisticsRes>;
export type SingleMyMailStatisticsResponse =
  SingleResponseResult<MyMailStatistics>;
export type ListMailResponse = ListResponseResult<Mail>;
export type SingleMailPreviewResponse = SingleResponseResult<MailPreview>;
export type SingleMailSendResponse = SingleResponseResult<boolean>;

export interface GetMailPreviewParams {
  positionId: number;
  userText: string;
}

export interface SendMailParams {
  positionId: number;
  subject: string;
  userText: string;
  recipientList: string[];
  referenceList: string[];
  hiddenReferenceList: string[];
}

export class MailAPIClass {
  resource = RESOURCE.MAIL;

  public getMyStatistics = async (
    startAt: Date,
    endAt: Date,
  ): Promise<SingleMyMailStatisticsResponse> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}/me/statistic`,
      {
        method: 'get',
        params: {
          startAt,
          endAt,
        },
      },
    );

    return axiosResponse.data;
  };

  public getAllStatistics = async (
    startAt: Date,
    endAt: Date,
  ): Promise<SingleMailStatisticsResponse> => {
    const axiosResponse = await requestApi(`${this.resource}/statistic`, {
      method: 'get',
      params: {
        startAt,
        endAt,
      },
    });

    return axiosResponse.data;
  };

  public getMails = async ({
    size,
    page,
    sort,
    ...params
  }: ListDataParams): Promise<ListMailResponse> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}/me`,
      {
        method: 'get',
        params: {
          size,
          page,
          sort,
          ...params,
        },
      },
    );

    return axiosResponse.data;
  };

  public getMailPreview = async (
    params: GetMailPreviewParams,
  ): Promise<SingleMailPreviewResponse> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}/previews`,
      {
        method: 'POST',
        data: {
          ...params,
        },
      },
    );

    return axiosResponse.data;
  };

  public sendMail = async (
    params: SendMailParams,
  ): Promise<SingleMailSendResponse> => {
    const axiosResponse = await requestApiWithAuthentication(this.resource, {
      method: 'POST',
      data: {
        ...params,
      },
    });

    return axiosResponse.data;
  };
}

export const MailAPI = new MailAPIClass();
