import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Login22 = Loadable(lazy(() => import('./pages/authentication/Login22')));
const OAuthCallback = Loadable(
  lazy(() => import('./pages/authentication/OAuthCallback')),
);
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery')),
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset')),
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/Register')),
);
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/VerifyCode')),
);

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const CandidateDetails = Loadable(
  lazy(() => import('./pages/dashboard/CandidateDetails')),
);
const CandidateCreate = Loadable(
  lazy(() => import('./pages/dashboard/CandidateCreate')),
);
const CandidateEdit = Loadable(
  lazy(() => import('./pages/dashboard/CandidateEdit')),
);
const CandidateList = Loadable(
  lazy(() => import('./pages/dashboard/CandidateList')),
);
const BatchJobDetails = Loadable(
  lazy(() => import('./pages/dashboard/BatchJobDetails')),
);
const BatchJobList = Loadable(
  lazy(() => import('./pages/dashboard/BatchJobList')),
);
const BatchJobWantedList = Loadable(
  lazy(() => import('./pages/dashboard/BatchJobWantedList')),
);
const CompanyDetails = Loadable(
  lazy(() => import('./pages/dashboard/CompanyDetails')),
);
const CompanyCreate = Loadable(
  lazy(() => import('./pages/dashboard/CompanyCreate')),
);
const CompanyEdit = Loadable(
  lazy(() => import('./pages/dashboard/CompanyEdit')),
);
const CompanyList = Loadable(
  lazy(() => import('./pages/dashboard/CompanyList')),
);
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const PositionCreate = Loadable(
  lazy(() => import('./pages/dashboard/PositionCreate')),
);
const PositionList = Loadable(
  lazy(() => import('./pages/dashboard/PositionList')),
);
const PositionDetails = Loadable(
  lazy(() => import('./pages/dashboard/PositionDetails')),
);
const PositionEdit = Loadable(
  lazy(() => import('./pages/dashboard/PositionEdit')),
);
const MailCreate = Loadable(lazy(() => import('./pages/dashboard/MailCreate')));
const MatchingBoard = Loadable(
  lazy(() => import('./pages/dashboard/MatchingBoard')),
);

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login22 />
          </GuestGuard>
        ),
      },
      {
        path: ':provider/callback',
        element: (
          <GuestGuard>
            <OAuthCallback />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login22 />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'register-unguarded',
        element: <Register />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'batch-jobs',
        children: [
          {
            path: '',
            element: <BatchJobList />,
          },
          {
            path: ':batchJobId',
            element: <BatchJobDetails />,
          },
        ],
      },
      {
        path: 'batch-jobs/wanted',
        children: [
          {
            path: '',
            element: <BatchJobWantedList />,
          },
        ],
      },
      {
        path: 'matchings',
        children: [
          {
            path: '',
            element: <MatchingBoard />,
          },
        ],
      },
      {
        path: 'candidates',
        children: [
          {
            path: '',
            element: <CandidateList />,
          },
          {
            path: ':candidateId',
            element: <CandidateDetails />,
          },
          {
            path: 'new',
            element: <CandidateCreate />,
          },
          {
            path: ':candidateId/edit',
            element: <CandidateEdit />,
          },
        ],
      },
      {
        path: 'companies',
        children: [
          {
            path: '',
            element: <CompanyList />,
          },
          {
            path: ':companyId',
            element: <CompanyDetails />,
          },
          {
            path: 'new',
            element: <CompanyCreate />,
          },
          {
            path: ':companyId/edit',
            element: <CompanyEdit />,
          },
        ],
      },
      {
        path: 'positions',
        children: [
          {
            path: '',
            element: <PositionList />,
          },
          {
            path: ':positionId',
            element: <PositionDetails />,
          },
          {
            path: ':positionId/edit',
            element: <PositionEdit />,
          },
          {
            path: 'new',
            element: <PositionCreate />,
          },
        ],
      },
      {
        path: 'mail',
        children: [
          {
            path: 'new',
            element: <MailCreate />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
    ],
  },
];

export default routes;
