import * as Sentry from '@sentry/browser';
import { toast } from 'react-hot-toast';

export default class ToastHelper {
  public static success(text: string) {
    return toast.success(text);
  }

  public static error(text: string) {
    Sentry.captureException(text);
    return toast.error(text);
  }

  public static info(text: string) {
    return toast(text);
  }

  public static jsx(text: string[]) {
    return toast(
      t => (
        <div style={{ width: '460px' }}>
          {text.map((t, i) => (
            <p
              key={i}
              style={{ width: '100%', wordWrap: 'break-word' }}
            >
              {t}
            </p>
          ))}
          <button onClick={() => toast.dismiss(t.id)}>닫기</button>
        </div>
      ),
      {
        duration: 400000,
        style: {
          width: '500px',
          maxWidth: 'none',
          marginLeft: 'calc(100% - 500px)',
        },
      },
    );
  }
}
