import { RESOURCE } from './CommonAPI';
import {
  SingleUserResponse,
  LoginResult,
  CheckEmailAndUsernameResult,
  ListResponseResult,
} from '../models/api/ResponseData';
import {
  paramsToFormData,
  putRequestWithFormData,
  requestApi,
  requestApiWithAuthentication,
} from './request';
import dataURItoBlob from '../utils/dataURItoBlob';
import { EnvChecker } from '../utils/EnvChecker';
import { User } from '../models/User';

interface OAuthConvertTokenParams {
  code: string;
  state: string;
  provider: string;
}

interface LoginParams {
  email: string;
  password: string;
}

interface RegisterParams {
  email: string;
  username: string;
  password: string;
}

interface SocialRegisterParams {
  email: string;
  username: string;
  uid: string;
  provider: string;
  profileImage: string;
}

interface CheckEmailParams {
  email: string;
}

interface CheckUsernameParams {
  username: string;
}

export interface UpdateUserParams {
  name?: string;
  userPictureImage?: File;
}

export interface OAuthConvertToken {
  data: SingleUserResponse;
  token: string;
}

export type ListUsersResponse = ListResponseResult<User>;

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost';

const TOKEN_HEADER_KEY = 'x-value-connect-access-token';
class UserAPIClass {
  resource = RESOURCE.USER;

  public getAuthGoogleCallback = (): string => {
    return `${BASE_URL}/authentication/google/callback`;
  };

  public oAuthConvertToken = async ({
    code,
    state,
    provider,
  }: OAuthConvertTokenParams): Promise<OAuthConvertToken> => {
    const axiosResponse = await requestApi(
      `${this.resource}/sign-in/${provider}/exchange`,
      {
        method: 'POST',
        data: {
          code,
          state,
          redirect_url: this.getAuthGoogleCallback(),
        },
      },
    );

    return {
      data: axiosResponse.data,
      token: axiosResponse.headers[TOKEN_HEADER_KEY],
    };
  };

  public login = async ({
    email,
    password,
  }: LoginParams): Promise<LoginResult> => {
    const axiosResponse = await requestApi(`${this.resource}/login`, {
      method: 'POST',
      data: {
        email,
        password,
      },
    });

    return axiosResponse.data;
  };

  public register = async ({
    email,
    username,
    password,
  }: RegisterParams): Promise<LoginResult> => {
    const axiosResponse = await requestApi(`${this.resource}/register`, {
      method: 'POST',
      data: {
        email,
        password,
        username,
      },
    });

    return axiosResponse.data;
  };

  public socialRegister = async ({
    email,
    username,
    uid,
    provider,
    profileImage,
  }: SocialRegisterParams): Promise<LoginResult> => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('username', username);
    formData.append('uid', uid);
    formData.append('provider', provider);
    formData.append(
      'profile_image',
      await dataURItoBlob(profileImage),
      'photo.jpg',
    );

    const axiosResponse = await requestApi(`${this.resource}/oauth/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });

    return axiosResponse.data;
  };

  public checkLogin = async (): Promise<SingleUserResponse> => {
    const axiosResponse = await requestApiWithAuthentication(
      `${this.resource}/me`,
      {
        method: 'GET',
      },
    );

    return axiosResponse.data;
  };

  public checkEmail = async ({
    email,
  }: CheckEmailParams): Promise<CheckEmailAndUsernameResult> => {
    const axiosResponse = await requestApi(`${this.resource}/check/email`, {
      method: 'GET',
      params: {
        email,
      },
    });

    return axiosResponse.data;
  };

  public checkUsername = async ({
    username,
  }: CheckUsernameParams): Promise<CheckEmailAndUsernameResult> => {
    const axiosResponse = await requestApi(`${this.resource}/check/username`, {
      method: 'GET',
      params: {
        username,
      },
    });

    return axiosResponse.data;
  };

  public loadUsers = async (): Promise<ListUsersResponse> => {
    const axiosResponse = await requestApi(`${this.resource}`, {
      method: 'GET',
    });

    return axiosResponse.data;
  };

  public updateUser = async (
    params: UpdateUserParams,
  ): Promise<SingleUserResponse> => {
    const axiosResponse = await putRequestWithFormData(
      `${this.resource}/me`,
      paramsToFormData(params),
    );

    return axiosResponse.data;
  };
}

export const UserAPI = new UserAPIClass();
