import { Stack } from '@mui/material';
import { sections } from '../../../gnb';
import DashboardNavbarLeftItem from './DashboardNavbarLeftItem';

const DashboardNavbarLeft = () => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
    >
      {sections.map(section => (
        <DashboardNavbarLeftItem
          key={section.title}
          mainItem={section}
        />
      ))}
    </Stack>
  );
};

export default DashboardNavbarLeft;
