import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { clearStorage, getAccessToken } from '../utils/storage';
import * as _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { uuid4 } from '@sentry/utils';
import qs from 'qs';
import { UnknownObject } from '../types/common';
import ToastHelper from '../utils/ToastHelper';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        clearStorage();
      }
      if (error.response.status !== 404) {
        Sentry.captureException(error);
      }
      // For QA
      // if response is client error or server error, show toast
      if (error.response.status >= 400) {
        ToastHelper.jsx([
          '에러 발생 (이 내용을 같이 스크린샷 찍어 제보 부탁드립니다 감사합니다)',
          `시간: ${new Date().toLocaleString()}`,
          `URL: ${error.config.url}`,
          `메소드: ${error.config.method}`,
          `상태 코드: ${error.response.status}`,
          `Query String: ${JSON.stringify(error.config.params)}`,
          `요청 데이터: ${JSON.stringify(error.config.data)}`,
          `응답 데이터: ${JSON.stringify(error.response.data)}`,
        ]);
      }
    }
    return Promise.reject(error);
  },
);

const API_HOST = process.env.REACT_APP_API_HOST;

class TokenNotExistError extends Error {}

function objectKeysToCamelCase(snake_case_object: UnknownObject) {
  const camelCaseObject = {};
  _.forEach(snake_case_object, (value, key: string) => {
    if (_.isPlainObject(value)) {
      // checks that a value is a plain object or an array - for recursive key conversion
      value = objectKeysToCamelCase(value as UnknownObject); // recursively update keys of any values that are also objects
    } else if (_.isArray(value)) {
      value = value.map(v => {
        if (_.isPlainObject(v)) {
          return objectKeysToCamelCase(v);
        }
        return v;
      });
    }

    // Check if the key contains Korean characters
    const isKoreanKey = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(key);

    if (isKoreanKey) {
      camelCaseObject[key] = value; // Keep Korean keys as they are
    } else {
      camelCaseObject[_.camelCase(key)] = value; // Convert English keys to camel case
    }
  });
  return camelCaseObject;
}

function objectKeysToSnakeCase(camelCaseObject: UnknownObject) {
  const snake_case_object = {};
  _.forEach(camelCaseObject, (value, key) => {
    if (_.isPlainObject(value)) {
      // checks that a value is a plain object or an array - for recursive key conversion
      value = objectKeysToSnakeCase(value as UnknownObject); // recursively update keys of any values that are also objects
    } else if (_.isArray(value)) {
      value = value.map(v => {
        if (_.isPlainObject(v)) {
          v = objectKeysToSnakeCase(v);
        }
        return v;
      });
    }

    // Check if the key contains Korean characters
    const isKoreanKey = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(key);

    if (isKoreanKey) {
      snake_case_object[key] = value; // Keep Korean keys as they are
    } else {
      snake_case_object[_.snakeCase(key)] = value;
    }
  });
  return snake_case_object;
}

function camelCaseToSnakeCase(requestBody) {
  if (requestBody == null) {
    return requestBody;
  }
  requestBody = objectKeysToSnakeCase(requestBody);

  return requestBody;
}

function snakeCaseToCamelCase(response: AxiosResponse) {
  if (response == null || response.data == null) {
    return response;
  }
  response.data = objectKeysToCamelCase(response.data);

  return response;
}

export async function getAuthorizationHeader() {
  const accessToken = getAccessToken();

  if (!accessToken) {
    throw new TokenNotExistError('로그인 하세여~');
  }
  // TODO : JWT 가 아님.
  // try {
  //   await verify(accessToken, (process.env as any).JWT_SECRET);
  // } catch (err) {
  //   if (err instanceof TokenExpiredError) {
  //     clearStorage();
  //   }
  //   throw err;
  // }

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      // "Content-Type": "application/x-www-form-urlencoded"
    },
  };
}

export async function getAnonymousUserHeader() {
  return {
    headers: {
      'X-Anonymous-User-Uid': uuid4(),
    },
  };
}

export function getRequestURL(path: string) {
  return `${API_HOST}/api/${path}`;
}

export async function requestApi(
  path: string,
  options?: AxiosRequestConfig,
  camelCaseTarget: boolean = false,
): Promise<AxiosResponse> {
  return axios({
    url: getRequestURL(path),
    withCredentials: false,
    validateStatus: (status: number) => status >= 200 && status < 300, // default
    ...options,
    params: camelCaseTarget
      ? options.params
      : camelCaseToSnakeCase(options.params),
    data: camelCaseTarget ? options.data : camelCaseToSnakeCase(options.data),
    maxContentLength: 2000000,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  }).then(result => (camelCaseTarget ? result : snakeCaseToCamelCase(result)));
}

export function paramsToFormData(
  params: any,
  camelCaseTarget: boolean = false,
): FormData {
  const parsedParams = camelCaseTarget ? params : camelCaseToSnakeCase(params);
  const formData = new FormData();

  function handleValue(key: string, value) {
    if (value instanceof Blob) {
      formData.append(key, value);
    } else if (value instanceof File) {
      formData.append(key, value);
    } else if (value !== null && value !== undefined) {
      formData.append(key, value.toString());
    }
  }

  Object.keys(parsedParams).forEach(key => {
    const value = parsedParams[key];
    if (value instanceof Array) {
      value.forEach(v => {
        handleValue(key, v);
      });
    } else {
      handleValue(key, value);
    }
  });

  return formData;
}

export async function postRequestWithFormData(
  path: string,
  formData: FormData,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse> {
  const authorizationHeader = await getAuthorizationHeader();
  return axios
    .post(getRequestURL(path), formData, {
      headers: { 'content-type': 'multipart/form-data' },
      withCredentials: false,
      validateStatus: (status: number) => status >= 200 && status < 300, // default
      maxContentLength: 2000000,
      ...options,
      ...authorizationHeader,
    })
    .then(result => snakeCaseToCamelCase(result));
}

export async function putRequestWithFormData(
  path: string,
  formData: FormData,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse> {
  const authorizationHeader = await getAuthorizationHeader();
  return axios
    .put(getRequestURL(path), formData, {
      headers: { 'content-type': 'multipart/form-data' },
      withCredentials: false,
      validateStatus: (status: number) => status >= 200 && status < 300, // default
      maxContentLength: 2000000,
      ...options,
      ...authorizationHeader,
    })
    .then(result => snakeCaseToCamelCase(result));
}

export async function deleteRequestWithFormData(
  path: string,
  formData: FormData,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse> {
  const authorizationHeader = await getAuthorizationHeader();
  return axios
    .delete(getRequestURL(path), {
      headers: { 'content-type': 'multipart/form-data' },
      data: formData,
      withCredentials: false,
      validateStatus: (status: number) => status >= 200 && status < 300, // default
      maxContentLength: 2000000,
      ...options,
      ...authorizationHeader,
    })
    .then(result => snakeCaseToCamelCase(result));
}

export async function requestApiWithAuthentication(
  path: string,
  options?: AxiosRequestConfig,
  camelCaseTarget: boolean = false,
): Promise<AxiosResponse> {
  const authorizationHeader = await getAuthorizationHeader();

  return requestApi(
    path,
    _.merge(options, authorizationHeader),
    camelCaseTarget,
  );
}

export async function requestApiWithAuthIfExist(
  path: string,
  options?: AxiosRequestConfig,
  camelCaseTarget: boolean = false,
): Promise<AxiosResponse> {
  const accessToken = getAccessToken();
  if (accessToken) {
    return requestApiWithAuthentication(path, options);
  }
  return requestApi(path, options, camelCaseTarget);
}

export async function requestAndProcess(
  apiCall,
  successCallback,
  errorCallback,
) {
  try {
    const response = await apiCall();
    if (response.success) {
      successCallback(response);
    } else {
      throw new Error(response.msg);
    }
  } catch (err) {
    errorCallback(err);
  }
}

export const __private = {
  objectKeysToCamelCase,
};
