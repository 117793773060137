import { User, UserRole } from '../models/User';

export default class UserUtils {
  public static getCurrentUserAvatar(currentUser: User): string {
    return currentUser.picture || this.getAvatarImage(currentUser.name);
  }

  public static getAvatarImage(avatarId?: string): string {
    return `https://api.adorable.io/avatars/40/${avatarId ?? Math.random()}`;
  }

  public static getUserRoleLabel(userRole: UserRole): string {
    const mapper = {
      [UserRole.USER]: '일반 사용자',
      [UserRole.ADMIN]: '관리자',
      [UserRole.GUEST]: '게스트',
    };
    return mapper[userRole];
  }
}
