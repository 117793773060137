// object에 있는 null값을 모두 빈 문자열로 만들어 주는 함수
const sanitize = obj => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      return value === null ? '' : value;
    }),
  );
};

export default sanitize;
