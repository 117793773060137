import ReceiptIcon from '@mui/icons-material/Receipt';
import BriefcaseIcon from './icons/Briefcase';
import ChartPieIcon from './icons/ChartPie';
import ClipboardListIcon from './icons/ClipboardList';
import FolderOpenIcon from './icons/FolderOpen';
import MailIcon from './icons/Mail';
import UserIcon from './icons/User';
import UsersIcon from './icons/Users';

export interface SubItem {
  title: string;
  path: string;
  icon: React.ReactElement;
}

export interface MainItem {
  title: string;
  icon: React.ReactElement;
  items: SubItem[];
}

export const sections: MainItem[] = [
  {
    title: 'General',
    icon: <ChartPieIcon fontSize="small" />,
    items: [
      {
        title: '오버뷰',
        path: '/dashboard',
        icon: <ChartPieIcon fontSize="small" />,
      },
      {
        title: '계정',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Management',
    icon: <BriefcaseIcon fontSize="small" />,
    items: [
      {
        title: '고객사',
        icon: <FolderOpenIcon fontSize="small" />,
        path: '/dashboard/companies?contract-status=CONTRACT_ON_GOING',
      },
      {
        title: '포지션',
        path: '/dashboard/positions?contract-status=CONTRACT_ON_GOING',
        icon: <BriefcaseIcon fontSize="small" />,
      },
      {
        title: '후보자',
        path: '/dashboard/candidates',
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: '포지션/후보자 매칭',
        path: '/dashboard/matchings',
        icon: <UsersIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Apps',
    icon: <ReceiptIcon fontSize="small" />,
    items: [
      {
        title: '메일러',
        path: '/dashboard/mail/new',
        icon: <MailIcon fontSize="small" />,
      },
      {
        title: '링크드인 크롤링',
        path: '/dashboard/batch-jobs',
        icon: <ReceiptIcon fontSize="small" />,
      },
      {
        title: '원티드 크롤링',
        path: '/dashboard/batch-jobs/wanted',
        icon: <ClipboardListIcon fontSize="small" />,
      },
    ],
  },
];
