import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { mailApi } from '../__fakeApi__/mailApi';
import type { AppThunk } from '../store';
import type { Email, Label } from '../types/mail';
import objFromArray from '../utils/objFromArray';
import { Position as PositionModel } from '../models/Position';
import { PositionAPI } from '../api/PositionAPI';
import { MailTemplate as MailTemplateModel } from '../models/MailTemplate';
import { MailTemplateAPI } from '../api/MailTemplateAPI';
import { GetMailPreviewParams, MailAPI, SendMailParams } from '../api/MailAPI';
import toast from 'react-hot-toast';
import { ErrorRounded } from '@mui/icons-material';
import { requestAndProcess } from '../api/request';

interface MailRefsCreateInput {
  value: string;
  error: boolean;
}

interface MailRefsCreate {
  recipientInput: MailRefsCreateInput;
  referenceInput: MailRefsCreateInput;
  hiddenReferenceInput: MailRefsCreateInput;
}

interface MailTemplateCreate {
  templateTitleInputValue: string;
  isTemplatePrivateInputValue: boolean;
}

interface MailState {
  refs: {
    recipients: string[];
    references: string[];
    hiddenReferences: string[];
    create: MailRefsCreate;
  };
  position: {
    searchPositionInputValue: string;
    selectPosition?: PositionModel;
    searchPositions: PositionModel[];
  };
  template: {
    searchInputValue: string;
    selectTemplate?: MailTemplateModel;
    searchTemplates: MailTemplateModel[];
    create: MailTemplateCreate;
  };
  contents: {
    title: string;
    messageBody: string;
  };
  preview: {
    isPreviewOpen: boolean;
    previewHtml: {
      loading: boolean;
      data: string;
      error: string;
    };
  };
  options: {
    isComposeOpen: boolean;
    isFullScreen: boolean;
  };
  send: {
    loading: boolean;
    error: string;
  };
}

const initialState: MailState = {
  refs: {
    recipients: [],
    references: [],
    hiddenReferences: [],
    create: {
      recipientInput: {
        value: '',
        error: false,
      },
      referenceInput: {
        value: '',
        error: false,
      },
      hiddenReferenceInput: {
        value: '',
        error: false,
      },
    },
  },
  position: {
    searchPositionInputValue: '',
    selectPosition: null,
    searchPositions: [],
  },
  template: {
    searchInputValue: '',
    selectTemplate: null,
    searchTemplates: [],
    create: {
      templateTitleInputValue: '',
      isTemplatePrivateInputValue: false,
    },
  },
  contents: {
    title: '',
    messageBody: '',
  },
  preview: {
    isPreviewOpen: false,
    previewHtml: {
      loading: false,
      data: '',
      error: '',
    },
  },
  options: {
    isComposeOpen: false,
    isFullScreen: true,
  },
  send: {
    loading: false,
    error: '',
  },
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    getRecipientInputValue(
      state: MailState,
      action: PayloadAction<string>,
    ): void {
      state.refs.create.recipientInput.value = action.payload;
    },
    getRecipientInputError(
      state: MailState,
      action: PayloadAction<boolean>,
    ): void {
      state.refs.create.recipientInput.error = action.payload;
    },
    getRecipients(state: MailState, action: PayloadAction<string[]>): void {
      state.refs.recipients = action.payload;
    },
    addRecipient(state: MailState, action: PayloadAction<string>): void {
      state.refs.recipients.push(action.payload);
    },
    removeRecipient(state: MailState, action: PayloadAction<string>): void {
      state.refs.recipients = state.refs.recipients.filter(
        rec => rec !== action.payload,
      );
    },
    getReferenceInputValue(
      state: MailState,
      action: PayloadAction<string>,
    ): void {
      state.refs.create.referenceInput.value = action.payload;
    },
    getReferenceInputError(
      state: MailState,
      action: PayloadAction<boolean>,
    ): void {
      state.refs.create.referenceInput.error = action.payload;
    },
    getReferences(state: MailState, action: PayloadAction<string[]>): void {
      state.refs.references = action.payload;
    },
    addReference(state: MailState, action: PayloadAction<string>): void {
      state.refs.references.push(action.payload);
    },
    removeReference(state: MailState, action: PayloadAction<string>): void {
      state.refs.references = state.refs.references.filter(
        ref => ref !== action.payload,
      );
    },
    getHiddenReferenceInputValue(
      state: MailState,
      action: PayloadAction<string>,
    ): void {
      state.refs.create.hiddenReferenceInput.value = action.payload;
    },
    getHiddenReferenceInputError(
      state: MailState,
      action: PayloadAction<boolean>,
    ): void {
      state.refs.create.hiddenReferenceInput.error = action.payload;
    },
    getHiddenReferences(
      state: MailState,
      action: PayloadAction<string[]>,
    ): void {
      state.refs.hiddenReferences = action.payload;
    },
    addHiddenReference(state: MailState, action: PayloadAction<string>): void {
      state.refs.hiddenReferences.push(action.payload);
    },
    removeHiddenReference(
      state: MailState,
      action: PayloadAction<string>,
    ): void {
      state.refs.hiddenReferences = state.refs.hiddenReferences.filter(
        ref => ref !== action.payload,
      );
    },
    getSearchPositionInputValue(
      state: MailState,
      action: PayloadAction<string>,
    ): void {
      state.position.searchPositionInputValue = action.payload;
    },
    getSelectPosition(
      state: MailState,
      action: PayloadAction<PositionModel>,
    ): void {
      state.position.selectPosition = action.payload;
    },
    removeSelectPosition(state: MailState): void {
      state.position.selectPosition = null;
    },
    getSearchPositions(
      state: MailState,
      action: PayloadAction<PositionModel[]>,
    ): void {
      state.position.searchPositions = action.payload;
    },
    getSearchTemplateInputValue(
      state: MailState,
      action: PayloadAction<string>,
    ): void {
      state.template.searchInputValue = action.payload;
    },
    getSelectTemplate(
      state: MailState,
      action: PayloadAction<MailTemplateModel>,
    ): void {
      state.template.selectTemplate = action.payload;
    },
    getTemplateTitleInputValue(
      state: MailState,
      action: PayloadAction<string>,
    ): void {
      state.template.create.templateTitleInputValue = action.payload;
    },
    getTemplateIsPrivateInputValue(
      state: MailState,
      action: PayloadAction<boolean>,
    ): void {
      state.template.create.isTemplatePrivateInputValue = action.payload;
    },
    getSearchTemplates(
      state: MailState,
      action: PayloadAction<MailTemplateModel[]>,
    ): void {
      state.template.searchTemplates = action.payload;
    },
    getTitle(state: MailState, action: PayloadAction<string>): void {
      state.contents.title = action.payload;
    },
    getMessageBody(state: MailState, action: PayloadAction<string>): void {
      state.contents.messageBody = action.payload;
    },
    openPreview(state: MailState): void {
      state.preview.isPreviewOpen = true;
    },
    closePreview(state: MailState): void {
      state.preview.isPreviewOpen = false;
    },
    loadPreviewHtml(state: MailState): void {
      state.preview.previewHtml.loading = true;
      state.preview.previewHtml.error = '';
    },
    getSuccessPreviewHtml(
      state: MailState,
      action: PayloadAction<string>,
    ): void {
      state.preview.previewHtml.loading = false;
      state.preview.previewHtml.data = action.payload;
    },
    getErrorPreviewHtml(state: MailState, action: PayloadAction<string>): void {
      state.preview.previewHtml.loading = false;
      state.preview.previewHtml.data = '';
      state.preview.previewHtml.error = action.payload;
    },
    openCompose(state: MailState): void {
      state.options.isComposeOpen = true;
    },
    closeCompose(state: MailState): void {
      state.options.isComposeOpen = false;
    },
    expandScreen(state: MailState): void {
      state.options.isFullScreen = true;
    },
    shrinkScreen(state: MailState): void {
      state.options.isFullScreen = false;
    },
    loadSendingMail(state: MailState): void {
      state.send.loading = true;
      state.send.error = '';
    },
    getSuccessSendingMail(state: MailState): void {
      state.send.loading = false;
    },
    getErrorSendingMail(state: MailState, action: PayloadAction<string>): void {
      state.send.loading = false;
      state.send.error = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getRecipientInputValue =
  (input: string = ''): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getRecipientInputValue(input));
  };

export const getRecipientInputError =
  (error: boolean = false): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getRecipientInputError(error));
  };

export const getRecipients =
  (recipients: string[] = []): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getRecipients(recipients));
  };

export const addRecipient =
  (recipient: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.addRecipient(recipient));
  };

export const removeRecipient =
  (recipient: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.removeRecipient(recipient));
  };

export const getReferenceInputValue =
  (input: string = ''): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getReferenceInputValue(input));
  };

export const getReferenceInputError =
  (error: boolean = false): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getReferenceInputError(error));
  };

export const getReferences =
  (references: string[] = []): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getReferences(references));
  };

export const addReference =
  (reference: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.addReference(reference));
  };

export const removeReference =
  (reference: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.removeReference(reference));
  };

export const getHiddenReferenceInputValue =
  (input: string = ''): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getHiddenReferenceInputValue(input));
  };

export const getHiddenReferenceInputError =
  (error: boolean = false): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getHiddenReferenceInputError(error));
  };

export const getHiddenReferences =
  (hiddenReferences: string[] = []): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getHiddenReferences(hiddenReferences));
  };

export const addHiddenReference =
  (reference: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.addHiddenReference(reference));
  };

export const removeHiddenReference =
  (reference: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.removeHiddenReference(reference));
  };

export const getSearchPositionInputValue =
  (searchInput: string = ''): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getSearchPositionInputValue(searchInput));
  };

export const getSelectPosition =
  (position: PositionModel | null = null): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getSelectPosition(position));
  };

export const getSelectPositionWithId =
  (positionId: number): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await PositionAPI.loadPosition(positionId);

    dispatch(slice.actions.getSelectPosition(response.data));
  };

export const removeSelectPosition =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.removeSelectPosition());
  };

export const getSearchPositions =
  (query: string = ''): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await PositionAPI.loadListData({
      page: 1,
      size: 30,
      sort: 'updatedAt-desc',
      query,
    });

    dispatch(slice.actions.getSearchPositions(response.data));
  };

export const getSearchTemplateInputValue =
  (searchInput: string = ''): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getSearchTemplateInputValue(searchInput));
  };

//export const getSelectTemplate =
//(template: MailTemplateModel | null = null): AppThunk =>
//async (dispatch): Promise<void> => {
//dispatch(slice.actions.getSelectTemplate(template));
//};

export const getSelectTemplateWithRequest =
  (templateId: number | null = null): AppThunk =>
  async (dispatch): Promise<void> => {
    if (templateId) {
      const response = await MailTemplateAPI.loadMailTemplate(templateId);

      dispatch(slice.actions.getSelectTemplate(response.data));
      dispatch(slice.actions.getMessageBody(response.data.content));
      dispatch(
        slice.actions.getTemplateIsPrivateInputValue(response.data.isPrivate),
      );
    } else {
      dispatch(slice.actions.getSelectTemplate(null));
    }
  };

export const getTemplateTitleInputValue =
  (title: string = ''): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getTemplateTitleInputValue(title));
  };

export const getTemplateIsPrivateInputValue =
  (isPrivate: boolean = false): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getTemplateIsPrivateInputValue(isPrivate));
  };

export const getSearchTemplates =
  (templates: MailTemplateModel[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getSearchTemplates(templates));
  };

export const getSearchTemplatesWithRequest =
  (query: string = ''): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await MailTemplateAPI.loadMailTemplates({
      query,
    });

    dispatch(slice.actions.getSearchTemplates(response.data));
  };

export const getTitle =
  (title: string = ''): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getTitle(title));
  };

export const getMessageBody =
  (messageBody: string = ''): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.getMessageBody(messageBody));
  };

export const openPreview =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openPreview());
  };

export const closePreview =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.closePreview());
  };

export const getPreviewHtml =
  (positionId: number, userText: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.loadPreviewHtml());
    const params: GetMailPreviewParams = {
      positionId,
      userText,
    };

    try {
      const response = await MailAPI.getMailPreview(params);
      if (response.success) {
        dispatch(slice.actions.getSuccessPreviewHtml(response.data.content));
      } else {
        throw new Error(response.msg);
      }
    } catch (err) {
      dispatch(slice.actions.getErrorPreviewHtml(err));
    }
  };

export const openCompose =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openCompose());
  };

export const closeCompose =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.closeCompose());
  };

export const expandScreen =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.expandScreen());
  };

export const shrinkScreen =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.shrinkScreen());
  };

export const sendMail =
  (sendMailParams: SendMailParams): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.loadSendingMail());

    const apiCall = () => MailAPI.sendMail(sendMailParams);
    const successCb = response => {
      dispatch(slice.actions.getSuccessSendingMail());
      toast.success('메일을 성공적으로 보냈습니다');
    };
    const errorCb = err => {
      dispatch(slice.actions.getErrorSendingMail(err));
      toast.error('메일 전송에 실패했습니다. 다시 시도해주세요');
    };

    await requestAndProcess(apiCall, successCb, errorCb);

    //try {
    //const response = await MailAPI.sendMail(sendMailParams);
    //if (response.success) {
    //dispatch(slice.actions.getSuccessSendingMail());
    //toast.success('메일을 성공적으로 보냈습니다');
    //} else {
    //throw new Error(response.msg);
    //}
    //} catch (err) {
    //dispatch(slice.actions.getErrorSendingMail(err));
    //toast.error('메일 전송에 실패했습니다. 다시 시도해주세요');
    //}
  };

export const mailSelector = (state): MailState => state.mail;
export const mailRefsSelector = state => mailSelector(state).refs;
export const mailOptionSelector = state => mailSelector(state).options;
export const mailTitleSelector = state => mailSelector(state).contents.title;
export const mailMessageBodySelector = state =>
  mailSelector(state).contents.messageBody;
export const mailPositionSelector = state => mailSelector(state).position;
export const mailTemplateSelector = state => mailSelector(state).template;
export const mailPreviewSelector = state => mailSelector(state).preview;
export const mailSendingSelector = state => mailSelector(state).send;
// TODO: Add detail mail selector and use it with useMemo in components

export default slice;
