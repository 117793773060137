import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import NavItem from '../../NavItem';
import { SubItem } from '../../../gnb';

interface DashboardNavbarLeftPopupProps {
  subItems: SubItem[];
}

const DashboardNavbarLeftPopup: FC<DashboardNavbarLeftPopupProps> = props => {
  const { subItems } = props;
  const location = useLocation();
  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      sx={{
        width: '100%',
      }}
    >
      {subItems.map(subItem => (
        <Box
          sx={{
            width: '100%',
          }}
          key={subItem.title}
        >
          <NavItem
            active={
              subItem.path
                ? !!matchPath(subItem.path, location.pathname)
                : false
            }
            title={subItem.title}
            depth={1}
            path={subItem.path}
          />
        </Box>
      ))}
    </Stack>
  );
};

export default DashboardNavbarLeftPopup;
