import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../__fakeApi__/authApi';
import { OAuthConvertToken, UserAPI } from '../api/UserAPI';
import { User } from '../models/User';
import { clearStorage, getAccessToken, setAccessToken } from '../utils/storage';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
  exchangeCode: (
    code: string,
    prevState: string,
    provider: string,
  ) => Promise<void>;
  updateUser: (user: User) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type ExchangeCodeAction = {
  type: 'EXCHANGE_CODE';
  payload: {
    user: User;
  };
};

type UpdateUserAction = {
  type: 'UPDATE_USER';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | ExchangeCodeAction
  | UpdateUserAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  EXCHANGE_CODE: (state: State, action: ExchangeCodeAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATE_USER: (state: State, action: UpdateUserAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  exchangeCode: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = props => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = getAccessToken();

        if (accessToken) {
          const response = await UserAPI.checkLogin();

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: response.data,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const accessToken = await authApi.login({ email, password });
    const response = await UserAPI.checkLogin();

    setAccessToken(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: response.data,
      },
    });
  };

  const logout = async (): Promise<void> => {
    clearStorage();
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email: string,
    name: string,
    password: string,
  ): Promise<void> => {
    const response = await UserAPI.register({
      email,
      username: name,
      password,
    });

    setAccessToken(response.token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: response.user,
      },
    });
  };

  const exchangeCode = async (
    code: string,
    prevState: string,
    provider: string,
  ): Promise<void> => {
    const { pathname, asPath } = JSON.parse(prevState);
    let redirectPath = '/';
    let redirectAs = '/';

    if (asPath && pathname && !pathname.includes('auth')) {
      redirectPath = pathname;
      redirectAs = asPath;
    }

    const data: OAuthConvertToken = await UserAPI.oAuthConvertToken({
      code,
      state: prevState,
      provider,
    });
    // yield put(actionCreators.convertTokenSignInSucceeded({
    //   token: data.token,
    //   user: data.data.data
    // }));
    // yield call(Router.push, redirectPath, redirectAs);
    //
    // const user = await authApi.me(accessToken);

    setAccessToken(data.token);

    dispatch({
      type: 'EXCHANGE_CODE',
      payload: {
        user: data.data.data,
      },
    });
  };

  const updateUser = async (user: User): Promise<void> => {
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        exchangeCode,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
